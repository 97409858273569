import {createTheme} from "@mui/material";



export const pleshDark = createTheme({
    typography: {
        fontFamily: ['Proto Grotesk']
    },

    components:{
        MuiAppBar: {
            styleOverrides:{
                root:{
                    background: 'rgba(28, 28, 61,1)',
                    borderRadius:'0',
                    border:'none'
                }
            }
        },
        MuiTextField:{
            styleOverrides:{
                root:{
                    '& fieldset':{
                        borderWidth:'3px !important',
                        borderRadius:'1rem'
                    }
                }
            }
        },
        MuiButton:{
            styleOverrides: {
                contained: {
                    borderRadius:'1rem',
                    color:'#f1ebe9',
                    backgroundColor: 'rgba(234,77,149,1)',
                    background: 'linear-gradient(90deg, rgba(231,110,81,1) 0%, rgba(234,77,149,1) 100%)'
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                login: {
                    padding: '.8rem 0',
                    margin: '.8rem 0',
                    borderRadius: '1rem',
                    border: '2px solid #4C4C7E',
                },
            }
        }
    },

    palette: {
        mode: 'dark',
        primary: {
            main: 'rgb(232, 93, 116)',
        },
        secondary: {
            main: '#263238',
        },
        background: {
            default: '#11112C',
            paper: '#1C1C3D',
        },
        accent: {
            main: '#e6fc69',
        },
        text: {
            primary: '#eceff1',
        }
    },

    customColors: {
        clap: {
            height: '100px',
            width:'100%',
            fontSize: '18px',
            background: 'linear-gradient(90deg, #a19cdf 0%, #eab1d1)',
            border:'none',
            color: '#f1ebe9',
            borderRadius: '2rem',
            borderWidth: '3px',
        }
    }
});
