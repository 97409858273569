import React from "react";
import {useParams} from "react-router-dom";
import {useObject} from "react-firebase-hooks/database";
import {ref} from "firebase/database";
import {db} from "../firebase/clientApp";

export const ClapNum = () => {
    const {id} = useParams()
    const [snapshots, loading, error] = useObject(ref(db,'users/plesh/events/'+id));

  return(
      <p>
          {
              (snapshots && !loading && !error && snapshots.val())
                  ? snapshots.val().applausi
                  : '0'
          }
      </p>
  )
}
