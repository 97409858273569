import React from "react";
import {Box, Popover} from "@mui/material";
import {ChromePicker} from "react-color";
import DialogContent from "@mui/material/DialogContent";
import {Palette} from "@mui/icons-material";

export const CustomColorPicker = ({color, handleUpdateColor}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    //const [open, setOpen] = useState(false)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    const updateColor = (event) => {
        handleUpdateColor(event.hex)
    }

    return (
        <Box>
            <Box onClick={handleClick} sx={{position:'relative', width: '2.5rem', height: '2.5rem', background: color, borderRadius:'0.4rem', border: '2px solid #4C4C7E',}}
                 display={'flex'}>
                <Palette sx={{margin: 'auto'}} fontSize={'small'}/>
            </Box>
            <Popover id={id}
                     open={open}
                     anchorEl={anchorEl}
                     onClose={handleClose}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'left',
                     }}
            >
                <DialogContent>
                    <ChromePicker color={color || '#37374e'} onChange={updateColor}/>
                </DialogContent>
            </Popover>
        </Box>
    )
}
