import {Box, Checkbox, FormControlLabel, Grid, IconButton, Paper, Stack, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {AddCircle, RemoveCircle} from "@mui/icons-material";
import {CustomColorPicker} from "../shared/CustomColorPicker";

const ColorForm = ({colori, handleSetColori, colorTransitionDuration, handleSetTransitionColor, colorLoop, handleSetColorLoop}) => {
    const [_colori, updateColori] = useState([])
    const [_transitionColor, updateTransitionColor] = useState([])

    useEffect(() => {updateColori(colori)},[colori])
    useEffect(() => {updateTransitionColor(colorTransitionDuration)},[colorTransitionDuration])

    function addNewColor() {
        let e = ''
        let r = _colori
        let newR = r?.concat(e)
        handleSetColori(newR)
    }

    function handleUpdateColor({i,value}) {
        let r = _colori
        r[i] = value
        handleSetColori(r)
    }

    function handleRemoveColor({i}) {
        let r = _colori
        r?.splice(i,1)
        handleSetColori(r)
    }

    /*function handleHideResult(event) {
        setHideResult(event.target.checked)
    }*/

    return (
        <Box sx={{pt: 3}}>
            <Grid item xs={12}>
                <TextField
                    type={'number'}
                    defaultValue={300}
                    label={'Durata (ms)'}
                    value={_transitionColor}
                    helperText={"Inserisci la durata della transizione da un colore all'altro (in ms)"}
                    onChange={(event) => {
                        if(event.target.value >= 0) {
                            updateTransitionColor(event.target.value);
                            handleSetTransitionColor(event.target.value);
                        }
                    }}
                />
            </Grid>
            {<Grid item xs={12}>
                <FormControlLabel control={<Checkbox onChange={(event, checked) => handleSetColorLoop(checked)} checked={colorLoop}/>}
                                  label="Crea un loop infinito tra i colori (fino a chè la domanda è in Play)"/>
            </Grid>}
            <br/>
            <Typography variant={"h5"}>Aggiungi i colori qui sotto</Typography>
            <Stack direction={"column"}>
                {_colori?.map((r,i) =>
                    <InputColor
                        i={i}
                        links={_colori[i]}
                        handleUpdateColor={handleUpdateColor}
                        handleRemoveColor={handleRemoveColor}
                    />
                )}
            </Stack>
            <IconButton onClick={addNewColor}>
                <AddCircle/>
            </IconButton>
        </Box>
    )
}

export default ColorForm

function InputColor({i, handleUpdateColor, handleRemoveColor, links}) {
    const [color, setColor] = useState({
        label: "",
        color: '#E85D74'
    })

    useEffect(() => setColor(links), [links])

    function handleSetColorLabel (event) {
        let value = {...color, label: event.target.value}

        setColor(value)
        handleUpdateColor({i, value})
    }

    const updateColor = (hex) => {
        let value = {...color, color: hex}

        setColor(value)
        handleUpdateColor({i, value})
    }

    return (
        <Grid my={0.5} container alignItems={'center'}>
            <Grid item xs={1}>
                <IconButton onClick={() => handleRemoveColor({i})}>
                    <RemoveCircle />
                </IconButton>
            </Grid>
            <Grid item xs={11} >
                <Grid component={Paper} p={1} container borderRadius={'12px'} alignItems={'end'}>
                    <Grid item xs={1.2}>
                        <CustomColorPicker color={color.color} handleUpdateColor={updateColor}/>
                    </Grid>
                    <Grid item xs={9.8}>
                        <TextField required value={color?.label} onChange={handleSetColorLabel} label={'Testo del color ' + (i + 1)} variant={"standard"} fullWidth/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
