import React, {useEffect, useState} from "react";
import {Box, Button, Stack, Typography} from "@mui/material";
import {useQuery} from "../../../custom-hooks/useQuery";
import {ThumbUp} from "@mui/icons-material";
import axios from "axios";
import {API_URL} from "../../../config";
import {useParams} from "react-router-dom";

const Bar = ({color, risposta, occurrence, vertical, radius, correct, elementPlaying}) => (
    <Stack sx={{
        width: vertical ? '7rem' : '100%',
        height: vertical ? '100%' : '4rem'
    }}
           justifyContent={vertical ? 'flex-end' : 'center'}
    >
        <div
            style={{
                width: vertical ? '100%' : occurrence + '%',
                maxWidth: '100%',
                minWidth: '0',
                borderRadius: radius ?
                    vertical ? `${radius}px ${radius}px 0 0` : `0 ${radius}px ${radius}px 0` : 'inherit',
                backgroundColor: color,
                height: vertical ? occurrence + '%' : '100%',
                transition: '1s ease',
                opacity: correct ?
                    risposta === elementPlaying.corretta ? '1' : '0.3' : '1'
            }}
        >
        </div>
    </Stack>
)

export default function QuizVisual({result, elementPlaying, colors, luma}) {
    const {id} = useParams()
    const [risposte, setRisposte] = useState([])
    const query = useQuery()
    const control = query.get('control') === 'true'
    const vertical = query.get('direction') === 'vertical'
    const radius = query.get('radius')
    const percentage = query.get('percentage')
    const correct = query.get('correct') === 'true'

    useEffect(() => {
        setRisposte(elementPlaying.risposte)
    }, [result])

    const handleBoostVotes = (answer) => {
        axios.post(`${API_URL}/answer/${id}/${elementPlaying.id}`, {
            answer: answer,
            user: 'admin'
        })
            .then(response => console.log("boosted",response))
            .catch( error => console.log("error boosting",error))
    }

    return (
        <div style={{width: '100%', height: '100%', textAlign: 'right', position: 'relative'}}>
            <Stack direction={vertical ? 'row' : 'column'} height={'90vh'} width={'100%'} spacing={5}
                   justifyContent={vertical ? 'center' : 'start'}
            >
                {risposte.map((risp, i) => {
                    const r = risp?.label || risp
                    return <Stack key={i} height={vertical ? '100%' : 'auto'} alignItems={vertical ? 'center' : 'start'}
                           justifyContent={vertical ? 'end' : 'start'}
                    >
                        {
                            control &&
                            <Button onClick={() => handleBoostVotes(risp.label || risp)} endIcon={<ThumbUp/>}>
                                {`Aggiungi il 10% dei voti alla risposta ${i+1}`}
                            </Button>
                        }
                        <Stack width={vertical ? 'auto' : '100%'} key={i}
                               height={'100%'}
                               position={"relative"}
                               direction={'column'}
                        >
                            <Bar occurrence={result.data ? result.data[r] : 0} color={luma ? "white" : colors[i] ?? colors[i-6] }
                                 barNum={risposte.length} vertical={vertical} radius={radius} correct={correct}
                                 elementPlaying={elementPlaying} percentage={percentage}
                                 risposta={r}/>
                            {percentage !== 'no' &&
                                <Typography textAlign={
                                    vertical ? 'center' :
                                        percentage === 'under' ? 'left' : 'inherit'}
                                            zIndex={'99'} component={"div"} variant={'h4'}
                                            sx={{
                                                pl: (percentage === 'under' || vertical) ? 0 : 1.3,
                                                pt: percentage === 'under' ? 1.3 : 0,
                                                position: percentage === 'under' ? 'relative' : 'absolute',
                                                top: (percentage !== 'under' && !vertical) ? '50%' : 'auto',
                                                left: vertical ? '50%' : '0',
                                                bottom: vertical ? 0 : 'auto',
                                                transform:
                                                    vertical ? 'translateX(-50%)' :
                                                        percentage !== 'under' ?
                                                            'translateY(-50%)'
                                                            :
                                                            'none',
                                                color: luma ? "white" : '#f1ebe9', opacity: correct ?
                                                    r === elementPlaying.corretta ? '1' : '0.3' : '1'
                                            }}>
                                    {result.data ? result.data[r] : 0}%
                                </Typography>}
                        </Stack>
                        <Box sx={{maxWidth: '87%', pt: 1}}>
                            <Typography textAlign={vertical ? 'center' : 'left'}
                                        sx={{
                                            fontSize: '41px',
                                            margin: '0',
                                            textTransform: 'uppercase',
                                            opacity: correct ?
                                                r === elementPlaying.corretta ? '1' : '0.3' : '1'
                                        }}>
                                {r}
                            </Typography>
                        </Box>
                    </Stack>
                })}
            </Stack>
        </div>
    )
}
