import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {Grid, IconButton} from "@mui/material";
import {useParams} from "react-router-dom";
import {useQuery} from "../../../custom-hooks/useQuery";
import {ThumbDown, ThumbUp} from "@mui/icons-material";
import {green, red} from "@mui/material/colors";
import axios from "axios";
import {API_URL, BUCKET} from "../../../config";
import {orderBy} from "lodash";

export const SendPhotoVisual = ({result, getData, domanda, luma}) => {
    const {id} = useParams()
    const query = useQuery()
    const [photos, setPhotos] = useState([])
    const columns = query.get('columns') ?? 12
    const spacing = query.get('spacing') ?? 8
    const order = query.get('order') ?? 'desc'
    const demo = query.get('demo') === 'true'

    useEffect(() => {
        if (result.data && result.data.length > 0 ) {
            if(demo){
                let p = []
                for (let i =0 ; i< 121; i++){
                    p.push({link:'https://placehold.it/500x500'})
                }
                setPhotos(p)
            } else {
                let p = result.data
                p = orderBy(p, 'createdAt', query.get('control') === 'true' ? 'asc' : order)
                /*p.sort(function(a, b) {
                    return b.createdAt - a.createdAt;
                });*/
                query.get('control') === 'true' ?
                    setPhotos(p)
                    :
                    setPhotos(p.filter(p => p.approved).slice(0, 132))
            }
        }
    },[result])

    const approvePhoto = async (photo) => {
        await axios
            .post(`${API_URL}/answer/${id}/${domanda}/photo/${photo.id}/${photo.approved ? 'block' : 'approve'}`)
            .then(r => {getData()})
    }

    return (
        <Grid container columns={columns}>
            {photos.map((p, i) =>
                <Grid item xs={1} key={i} sx={{marginBottom:'-6px'}}>
                    <Box sx={{width:'100%', background: luma ? 'white'
                            : 'auto',padding:spacing + 'px'}}
                         className={'photo-container'}>
                        {query.get('control') === 'true' && <Box className={'photo-commands'}>
                            <Box>
                                <IconButton onClick={() => approvePhoto(p)}
                                            sx={{background: p.approved ? `${red[500]} !important` : `${green[500]} !important`}}>
                                    {p.approved ? <ThumbDown/> : <ThumbUp/>}
                                </IconButton>
                            </Box>
                        </Box>}
                        {!luma && <img style={{
                            border: query.get('control') === 'true' ?
                                p.approved ? `5px solid ${green[500]}` : `5px solid ${red[500]}` : 'none',
                            borderRadius: query.get('control') === 'true' ?
                                '8px': 'none'
                        }}
                              className={'photo'}
                              src={demo ? p.link : `${BUCKET}/${id}/${domanda}/${p.filename}`}/>}
                    </Box>
                </Grid>
            )}
        </Grid>

    )
}
