import * as React from 'react';
import {useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Checkbox, Fab, FormControlLabel, FormGroup} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export default function AddEventForm({handleAddEvent}) {
    const [open, setOpen] = React.useState(false);
    const [eventName, setEventName] = React.useState('');
    const [autenticazione, setAutenticazione] = useState(false)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleNameChange = (event) => {
        setEventName(event.target.value)
    }

    const handleSetAutenticaizone = (event) => {
        setAutenticazione(event.target.checked)
    }

    const handleAdd = () => {
        if (eventName !== '') {
            let ev = {
                title: eventName,
                applausometro: true,
                agenda: false,
                squadre: false,
                logo: true,
                autenticazione,
                titoloAgenda:'Agenda',
                contenutoAgenda:'',
                theme:"default",
            }
            handleAddEvent(ev)
            handleClose();
        }
    }

    const fabStyle = {
        position: 'absolute',
        bottom: 16,
        right: 16,
    };

    const AddEvent = () => (
        <Fab onClick={handleClickOpen} sx={fabStyle} aria-label={'Add'} color={"primary"}>
            <AddIcon/>
        </Fab>
    )

    return (
        <div>
            <AddEvent/>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Subscribe</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Add an event
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Event name"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={handleNameChange}
                    />
                    <FormGroup sx={{mt:2}}>
                        <FormControlLabel control={<Checkbox checked={autenticazione} onChange={handleSetAutenticaizone}/>} label="L'evento richiede autenticazione" />
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleAdd}>Add</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
