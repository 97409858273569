import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {Api, BarChart} from "@mui/icons-material";
import {Collapse} from "@mui/material";
import {Link} from "react-router-dom";

const sections = [
    {
        title: 'Grafici',
        icon: <BarChart/>,
        content: [
            {
                title: 'Sondaggio',
                link: '/documentation/poll'
            },
            {
                title: 'Wordcloud',
                link: '/documentation/wordcloud'
            }
        ]
    },
    {
        title: 'Api',
        icon: <Api/>,
        content: [
            {
                title: 'Invia dati',
                link: '/documentation/post'
            },
            {
                title: 'Recupera dati',
                link: '/documentation/get'
            }
        ]
    }
]

export const DocsDrawer = () => {

    const DrawerItem = ({content}) => {
        const [opened, setOpened] = React.useState(false)
        return (
            <Box key={content.title}>
                <ListItemButton onClick={() => setOpened(!opened)}>
                    <ListItemIcon>
                        {content.icon}
                    </ListItemIcon>
                    <ListItemText primary={content.title}/>
                </ListItemButton>
                <Collapse in={opened} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {content.content.map((item) =>
                            <ListItemButton key={item.title} sx={{pl: 4}} component={Link} to={item.link}>
                                <ListItemText primary={item.title}/>
                            </ListItemButton>
                        )}
                    </List>
                </Collapse>
            </Box>
        )
    }

    return (
        <div>
            <Toolbar>
                <Box sx={{width: '100%', height: '4rem', p: 1}} component={Link} to={'/documentation'}>
                    <img style={{width: '100%', height: '100%', objectFit: 'contain'}}
                         src={require('../../assets/images/logo-long.png')} alt={'logo'}/>
                </Box>
            </Toolbar>
            <Divider/>
            <List>
                {sections.map((content, index) => (
                    <DrawerItem content={content}/>
                ))}
            </List>
        </div>
    )
}
