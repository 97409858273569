import * as React from 'react';
import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import QrCode2Icon from "@mui/icons-material/QrCode2";
import {Button, DialogContent, IconButton, Slide, Snackbar, Stack, Typography} from "@mui/material";
import QRCode from "react-qr-code";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function SimpleDialog(props) {
    const { onClose, selectedValue, open, event } = props;
    const [publicLink, setPublicLink] = useState('')
    const [controlLink, setControlLink] = useState('')
    const [snackOpen, setSnackOpen] = useState(false)


    useEffect(()=>{
        setPublicLink('https://vote.2ndstage.app/vote/'+event.id)
        setControlLink('https://control.2ndstage.app/control/'+event.id)
    }, [])

    const handleClose = () => {
        onClose(selectedValue);
    };

    const Qr = ({url, title, })=> (
        <Stack spacing={2}>
            <Typography variant={'h4'}>{title}</Typography>
            <QRCode value={url} bgColor={'transparent'} fgColor={'#eceff1'}/>
            <Button variant={'contained'} onClick={()=>copyLink(url)}>Copia Link</Button>
        </Stack>
    )

    const copyLink = (url) => {
        navigator.clipboard.writeText(url).then(r => setSnackOpen(true))
    }

    return (
        <Dialog onClose={handleClose} open={open} TransitionComponent={Transition}>
            <DialogContent>
                <Stack>
                    <Qr url={publicLink} title={'Votazione'}/>
                    {/*<Qr url={controlLink} title={'Control'}/>*/}
                </Stack>
            </DialogContent>
            <Snackbar
                open={snackOpen}
                autoHideDuration={2000}
                message={'Link copiato!'}
                onClose={()=>setSnackOpen(false)}
            />
        </Dialog>
    )
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default function QrButton({event}) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    return (
        <div>
            <IconButton onClick={handleClickOpen} aria-label="delete" size={"large"}>
                <QrCode2Icon fontSize={"inherit"}/>
            </IconButton>
            <SimpleDialog
                event={event}
                open={open}
                onClose={handleClose}
            />
        </div>
    );
}
