import React from "react";
import {Box, Checkbox, FormControlLabel, Grid} from "@mui/material";

export default function SendPhotoForm({hideResult = false, handleHideResult}) {
    return (
        <Box sx={{pt: 3}}>
            <Grid item xs={12}>
                <FormControlLabel control={<Checkbox onChange={(event, checked) => handleHideResult(checked)} checked={hideResult}/>}
                                  label="Approvazione manuale delle foto"/>
            </Grid>
        </Box>
    )
}
