import React, {useEffect} from 'react';
import {Box, CardContent, IconButton, Paper, Slider, Typography} from "@mui/material";
import {BUCKET} from "../../../../config";
import {Pause, PlayArrow} from "@mui/icons-material";
import {useParams} from "react-router-dom";

export const AudioPlayer = ({domanda, current, handlePlayPause}) => {
    const {id} = useParams()
    const audioref = React.useRef(null)
    const [duration, setDuration] = React.useState(0);
    const [isReady, setIsReady] = React.useState(false);
    const [isPlaying, setIsPlaying] = React.useState(false);
    const [progress, setProgress] = React.useState(0);

    const togglePlayPause = () => {
        if (isPlaying) {
            audioref.current?.pause();
            setIsPlaying(false);
        } else {
            audioref.current?.play();
            setIsPlaying(true);
        }
    };

    useEffect(() => {
        setIsReady(false)
        if (current !== '') {
            audioref.current?.load();
        }
    }, [current])

    useEffect(() => {
        if (isReady) {
            audioref.current?.play();
        }
    }, [isReady]);

    function formatDurationDisplay(duration) {
        if (!duration || duration===Infinity) return "00:00";
        const min = Math.floor(duration / 60);
        const sec = Math.floor(duration - min * 60);
        const formatted = [min, sec].map((n) => (n < 10 ? "0" + n : n)).join(":"); // format - mm:ss
        return formatted;
    }

    return (
        <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
            <CardContent>
                <Slider min={0} max={duration} defaultValue={0} aria-label="Default" value={progress}
                        onChange={(e) => {
                            if (!audioref.current) return;
                            audioref.current.currentTime = e.target.value;

                            setProgress(e.target.value);
                        }}
                />
                <Typography>
                    {isReady ? `${formatDurationDisplay(progress)}/${formatDurationDisplay(duration)}` : "00:00/00:00"}
                </Typography>
                <Typography variant={'h6'} textAlign={'center'}>
                    {current !== '' ? current : 'Seleziona un audio'}
                </Typography>
                {current !== '' && <audio
                    ref={audioref}
                    controls={false}
                    preload={'metadata'}
                    onDurationChange={(e) => setDuration(e.currentTarget.duration)}
                    onCanPlay={() => {
                        setIsReady(true);
                    }}
                    onPlaying={() => setIsPlaying(true)}
                    onPause={() => setIsPlaying(false)}
                    onEnded={() => handlePlayPause(current)}
                    onTimeUpdate={(e) => setProgress(e.currentTarget.currentTime)}
                    src={`${BUCKET}/${id}/${domanda}/${current}`}
                    muted={false}
                >

                </audio>}
                <Box sx={{textAlign:'center'}}>
                    <IconButton variant={'contained'} onClick={togglePlayPause} disabled={current === ''}>
                        {isPlaying ? <Pause/> : <PlayArrow/>}
                    </IconButton>
                </Box>
            </CardContent>
        </Paper>

    )
}
