import React, {useEffect, useMemo, useState} from 'react';
import {Box, IconButton, Typography} from "@mui/material";
import {useDocument} from "react-firebase-hooks/firestore";
import {firestore} from "../../firebase/clientApp";
import {useParams} from "react-router-dom";
import {OpenInNew} from "@mui/icons-material";

const CountdownTimer = ({timerRef, label, initialTime, isLive, startedAt}) => {
    const {id} = useParams()
    // Initial time in seconds (1 hour)
    const [timeRemaining, setTimeRemaining] = useState(initialTime);

    const progress = useMemo(() => {
        if(!startedAt)
            return 0

        return ((new Date()).getTime() - (new Date(!!startedAt?.seconds ? (startedAt?.seconds * 1000) : startedAt)).getTime()) / 1000
    }, [startedAt])

    useEffect(() => {
        setTimeRemaining(Math.floor(initialTime - progress))
    }, [initialTime, progress])

    useEffect(() => {
        if(isLive && (timeRemaining > 0)) {
            timerRef = setInterval(() => {
                setTimeRemaining((time) => {
                    if (time === 0) {
                        clearInterval(timerRef);
                        return 0;
                    } else return time - 1;
                });
            }, 1000);
        } else {
            clearInterval(timerRef);
            //setTimeRemaining(initialTime)
        }
        return () => clearInterval(timerRef)
    }, [isLive, timeRemaining]);

    // Convert seconds to hours, minutes, and seconds
    const hours = Math.floor(timeRemaining / 3600);
    const minutes = Math.floor((timeRemaining % 3600) / 60);
    const seconds = timeRemaining % 60;

    return (
        <Box margin={'auto'} textAlign={'center'}>
            {label && <Typography>{label}</Typography>}
            <Typography className={timeRemaining <= 0 && 'flashing'} color={timeRemaining <= 0 ? 'green' : isLive ? 'error' : 'inherit'} variant={'h5'}>
                {`${hours > 0 ? hours : '00'}:${minutes > 0 ? minutes : '00'}:${seconds > 0 ? seconds : '00'}`}
            </Typography>
        </Box>
    );
};

const CountdownTimerWrapper = ({initialTime, label = "", isLive, startedAt}) => {
    const {id} = useParams()
    const [event, eventLoading, eventError] = useDocument(firestore.doc('users/plesh/events/' + id))

    const [_initialTime, _label, _isLive, _startedAt] = useMemo(() => {
        if(!initialTime) {
            if(event && !eventLoading && !eventError) {
                return [event.data()?.timer?.duration || 0, event.data()?.timer?.label, !!event.data()?.timer?.startedAt, event.data()?.timer?.startedAt]
            }
        } else {
            return [initialTime, label, isLive, startedAt]
        }

        return [null, null, null, null]
    }, [initialTime, label, isLive, startedAt, event, eventLoading, eventError])

    let timer
    return (
        _initialTime && <CountdownTimer timerRef={timer} label={_label} initialTime={_initialTime} isLive={_isLive} startedAt={_startedAt}/>
    );
};

export default CountdownTimerWrapper;