import React, {useState} from "react";
import {Box, Button, Card, Container, Stack, TextField, Typography} from "@mui/material";
import logo from '../assets/images/logo-full.png'
import {auth} from "../firebase/clientApp";
import {useNavigate} from "react-router-dom";

export const Login = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate()

    const logIn = async (e) => {
        e.preventDefault()
        await auth.signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                navigate('/', {replace: true})
            })
            .catch((error) => {
                let errorCode = error.code;
                let errorMessage = error.message;
            });

    }

    return(
        <Box sx={{textAlign:'center', width:'100%', mt:10}}>
            <img src={logo} style={{width:'100%',maxWidth:'17.563rem'}}/>
            <Container maxWidth={'sm'}>
                <Typography variant={"h4"} fontWeight={'bold'} sx={{my: 5}} textTransform={'uppercase'} color={'white'}>
                    Inserisci le credenziali
                </Typography>
                <Card variant={'login'}>
                    <form style={{padding: '1rem 1rem', textAlign: 'left'}} onSubmit={logIn}>
                        <Stack direction={"column"} spacing={4}>
                            <TextField onChange={(e) => setEmail(e.target.value)} label={'Email'}/>
                            <TextField type='password' onChange={(e) => setPassword(e.target.value)} label={'Password'}/>
                            <Box sx={{textAlign: 'center'}}>
                                <Button sx={{my: 2}} variant={"contained"} type={"submit"}>
                                    Entra
                                </Button>
                            </Box>
                        </Stack>
                    </form>
                </Card>
            </Container>
        </Box>
    )
}
