import React, {useMemo, useState} from "react";
import {firestore} from '../firebase/clientApp'
import Event from "../components/Event";
import AddEventForm from "../components/AddEventForm";
import 'firebase/compat/firestore';
import {Box, CircularProgress, Container} from "@mui/material";
import {ToggleButton, ToggleButtonGroup} from "@mui/lab";
import {Main} from "../layouts/main";
import {useCollection} from "react-firebase-hooks/firestore";
import {baseTheme} from "../config";
import {useNavigate} from "react-router-dom";

export default function Events() {
    const [archived, setArchived] = useState(false)
    const ref = firestore.collection('users/plesh/events')
    const [events, loading, error] = useCollection(ref)
    const navigate = useNavigate()

    const eventsData = useMemo(() => {
        if(!events) {
            return null
        }

        return (events
                .docs
                .filter(c => {return archived ? c.data().archived : !c.data().archived})
                .sort((a, b) => {
                    return a._document?.version?.timestamp?.seconds > b._document?.version?.timestamp?.seconds ? -1 : 1;
                }))
    }, [events, archived])

    const handleChangeArchived = (event, newArchived) => {
        setArchived(newArchived);
    };

    async function handleAddEvent(newEvent) {
        const event = {...newEvent, playing:false, elementPlaying:''};
        await firestore
            .collection("users/plesh/events")
            .add(event)
            .then((doc) => {
                firestore
                    .collection("users/plesh/events/"+doc.id+"/themes").doc('default').set({
                        background: baseTheme.background,
                        colors: [...baseTheme.colors],
                        text: baseTheme.text,
                        created_at: new Date(),
                        editable: false
                    })

                navigate(`/events/${doc.id}`)
            })
    }

    function handleRemoveEvent(eventToRemove) {
        firestore
            .collection("users/plesh/events")
            .doc(eventToRemove)
            .delete()
            .then(() => {
                /**/
            }).catch((error) => {
                /**/
            });
    }

    const EventList = ({events}) => (
        events
            .map((event) =>
                <Event key={event.id} event={{...event.data(), id: event.id}} handleRemoveEvent={handleRemoveEvent}/>)
    )

    return (
        <Main>
            <Container>
                <Box sx={{py: 2}}>
                    <ToggleButtonGroup
                        value={archived}
                        exclusive
                        onChange={handleChangeArchived}
                        aria-label="Platform"
                    >
                        <ToggleButton value={false}>Attivi</ToggleButton>
                        <ToggleButton value={true}>Archiviati</ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                {
                    loading ?
                        <Box sx={{width:'100%', textAlign:'center'}}>
                            <CircularProgress/>
                        </Box>
                        :
                        (!error && eventsData) && <EventList events={eventsData}/>
                }
                <AddEventForm handleAddEvent={handleAddEvent}/>
            </Container>
        </Main>
    )
}
