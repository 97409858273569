import React from "react";
import {Box, Typography} from "@mui/material";
import {documentation} from "../../assets/data/documentation";

export const DocsContent = ({page}) => {

    const PageContent = () => {
        let d = documentation.find(d =>
            d.id === page
        )
        return (
            <Box>
                <Typography variant={'h3'} fontWeight={600}>
                    {d.title}
                </Typography>
                <Typography variant={'subtitle1'} sx={{opacity:'0.8'}}>
                    {d.subtitle}
                </Typography>
                {d.sections.map((s,i) =>
                    <Box key={i} sx={{py:2}}>
                        {s.content}
                    </Box>
                )}
            </Box>
        )
    }

    return (
        <Box>
            <PageContent/>
        </Box>
    )
}
