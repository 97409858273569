import {Alert, Button, IconButton, Typography} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import {useState} from "react";

const CustomConfirmDialog = ({
                                 disabled = false,
                                 label,
                                 confirmAction,
                                 icon,
                                 size = 'medium',
                                 color = 'accent',
                                 variant,
                                 confirmText = `Sei sicuro?`
                             }) => {
    const [openConfirmDialog, setOpenConfirmDialog] = useState({open: false, confirmAction: null, responseMessage: ''});

    const handleToggleConfirmDialog = (confirmAction, responseMessage) => {
        setOpenConfirmDialog({
            ...openConfirmDialog,
            open: !openConfirmDialog.open,
            responseMessage: responseMessage || null,
            confirmAction: confirmAction || null
        })
    }

    return (
        <>
            {label ?
                <Button startIcon={icon}
                        color={color}
                        variant={variant}
                        size={size}
                        disabled={disabled}
                        autoFocus
                        onClick={() => handleToggleConfirmDialog(confirmAction)}
                >
                    {label}
                </Button>
                : <IconButton sx={{color: color}}
                              size={size}
                              autoFocus
                              disabled={disabled}
                              onClick={() => handleToggleConfirmDialog(confirmAction)}>
                    {icon}
                </IconButton>}
            <Dialog open={openConfirmDialog.open} onClose={handleToggleConfirmDialog}>
                <DialogContent>
                    <DialogContentText>
                        <Typography>{confirmText}</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleToggleConfirmDialog}>{'Annulla'}</Button>
                    <Button variant={'contained'} autoFocus
                            onClick={openConfirmDialog.confirmAction ? () => {
                                try {
                                    openConfirmDialog.confirmAction()
                                    setOpenConfirmDialog({open: false, confirmAction: null, responseMessage: ''})
                                } catch (err) {
                                    console.log("err:", err)
                                    setOpenConfirmDialog({
                                        ...openConfirmDialog,
                                        responseMessage: "err"
                                    })
                                }
                            } : null}
                    >
                        {'CONFERMA'}
                    </Button>
                </DialogActions>
                {!!openConfirmDialog?.responseMessage &&
                    <Alert elevation={6} variant={"filled"}
                           severity={'error'}
                           sx={{width: '100%'}}>
                        {openConfirmDialog?.responseMessage || ''}
                    </Alert>}
            </Dialog>
        </>
    );
}

export default CustomConfirmDialog