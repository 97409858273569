import React, {useEffect, useState} from "react";
import {Stack} from "@mui/material";
import {useParams} from "react-router-dom";
import {useQuery} from "../../../custom-hooks/useQuery";

const SentimentVisual = ({result,colors, luma}) => {
    const {mode} = useParams()
    const [si, setSi] = useState(0)
    const [no, setNo] = useState(0)
    const query = useQuery()



    useEffect(()=>{
        setSi(result.data ? result.data.yes : 0)
        setNo(result.data ? result.data.no : 0)
    },[result])
    return(
        <div style={{width: '100%', position:'relative', marginTop:'3rem'}}>
            <Stack direction={"row"} justifyContent={"space-between"} sx={{mb:3}}>
                <p style={{margin:'0', fontSize: '30px'}}>Sì</p>
                <p style={{margin:'0', fontSize: '30px'}}>No</p>
            </Stack>
            <div style={{opacity:'0.4',transition: '1s ease', position:'absolute', height: !mode ? '6vh' : '12vh', left:'50%', borderLeft:query.get('transparent') === 'true' ? 'none' : `7px solid ${luma ? "white" : '#fde451'}`, borderRadius:'1rem'}}>

            </div>
            <div style={{transition: '1s ease', position:'absolute', height: !mode ? '8vh' : '16vh', left: no+'%', borderLeft:'7px solid white', borderRadius:'1rem', bottom:!mode ? '-1.1vh' : '-2vh'}}>

            </div>
            {
                colors.length > 0 &&
                    <Stack direction={"row"} style={{borderRadius: '2rem'}}>
                        <div style={{height: !mode ? '6vh' : '12vh', background: query.get('transparent') === 'true' ? 'transparent' : `linear-gradient(90deg, ${luma ? "white" : colors[0]} 0%, ${luma ? "white" : colors[1]} 100%)`, width:'100%', borderRadius: '2rem'}}/>
                    </Stack>
            }
        </div>
    )
}

export default SentimentVisual
