import * as React from 'react';
import {DocsLayout} from "../layouts/DocsLayout";
import {Container} from "@mui/material";
import {DocsContent} from "../components/Documentation/DocsContent";
import {useParams} from "react-router-dom";

function Documentation(props) {
    const {page} = useParams();

    return (
        <DocsLayout props={props}>
            <Container maxWidth="lg">
                <DocsContent page={page ?? 'home'}/>
            </Container>
        </DocsLayout>
    );
}
export default Documentation;
