import React, {useEffect, useRef, useState} from "react";
import {Card, CardContent, Container, Grid} from "@mui/material";
import {firestore} from "../firebase/clientApp";
import {useParams} from "react-router-dom";
import ControlContent from "../components/Control/ControlContent";
import ControlGrafici from "../components/Control/ControlGrafici";
import Box from "@mui/material/Box";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";

function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}

function Control() {
    const {id, mode, domanda} = useParams()
    const [idPlaying, setIdPlaying] = useState('')
    const prevIdPlaying = usePrevious(idPlaying)
    const url = 'users/plesh/events/' + id
    const [event, eventLoading, eventError] = useDocument(firestore.doc('users/plesh/events/' + id))
    const [themes, themesLoading, themesError] = useCollection(firestore.collection('users/plesh/events/' + id + '/themes'))

    const [activeTheme, setActiveTheme] = useState({})

    useEffect(() => {
        if (event && !eventLoading && !eventError && !themesLoading && !themesError && themes){
            let t = themes.docs.find(t => t.id === event.data().theme)
            setActiveTheme(t)
        }
    }, [event, themes])

    useEffect(() => {
        getIdPlaying()
    }, [])

    function getIdPlaying() {
        firestore
            .collection('users/plesh/events')
            .doc(id)
            .onSnapshot(doc => {
                if (doc.data().elementPlaying !== '') {
                    setIdPlaying(() => doc.data().elementPlaying)
                } else {
                    setIdPlaying(prevIdPlaying)
                }
            })
    }

    return (
        <Grid container style={{height: '100vh'}}>
            {(event && !eventError && !eventLoading && Object.keys(activeTheme).length > 0) &&
                    (!mode || mode === 'grafici') &&
                        <Grid item xs={12} sx={{py: 1}} style={{height: !mode ? '55%' : '100%',/* overflow: 'scroll'*/}}>
                            <Container maxWidth={'xxl'} style={{height: '100%', /*overflow: 'scroll'*/}}>
                                {!mode && <Card style={{height: '100%'}}>
                                    <CardContent style={{height: '100%'}}>
                                        <ControlGrafici/>
                                    </CardContent>

                                </Card>}
                                {mode === 'grafici' && <Box style={{height: '100%'}}>
                                    <ControlGrafici/>
                                </Box>}
                            </Container>
                        </Grid>}
                    {(!mode || mode === 'domanda') &&
                        <Grid item xs={12} sx={{background: 'transparent'}}
                              style={{height: !mode ? '45%' : '100%', borderRadius: '50px 50px 0 0'}}>
                            <ControlContent idPlaying={domanda ? domanda : idPlaying} url={url} eventId={id} activeTheme={activeTheme ?? {}}/>
                        </Grid>}
        </Grid>
    )
}

export default Control
