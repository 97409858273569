import React, {useEffect} from "react";
import {
    Box, Stack
} from "@mui/material";
import {wordcloudOptions} from "../../../assets/data/documentation/wordcloudOptions";
import {SimulatorField} from "./SimulatorField";
import {sharedOptions} from "../../../assets/data/documentation/sharedOptions";

const options = [
    ...sharedOptions,
    ...wordcloudOptions
]

export const WordcloudSimulator = ({handleSetParam, handleInitializeParams}) => {

    useEffect(() => {
        handleInitializeParams(options)
    }, [])

    return (
        <Box>
            <Stack spacing={2}>
                {
                    options.map((option, index) =>
                        <SimulatorField handleSetParam={handleSetParam} field={option} font={option.param === 'font'}/>
                    )
                }
            </Stack>
        </Box>
    )
}
