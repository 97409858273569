import React from "react";
import {Box, Typography} from "@mui/material";
import {PollSimulator} from "./PollSimulator";
import {WordcloudSimulator} from "./WordcloudSimulator";
import {QuizSimulator} from "./QuizSimulator";
import {SendPhotoSimulator} from "./SendPhotoSimulator";

export const SimulatorContent = ({type, handleInitializeParams, handleSetParam}) => {
    return (
        <Box sx={{py: 2}}>
            <Typography variant={'h6'} sx={{pb:2}}>
                Scegli i parametri:
            </Typography>
            {type === 'poll' && <PollSimulator handleSetParam={handleSetParam} handleInitializeParams={handleInitializeParams}/>}
            {type === 'quiz' && <QuizSimulator handleSetParam={handleSetParam} handleInitializeParams={handleInitializeParams}/>}
            {type === 'wordcloud' && <WordcloudSimulator handleSetParam={handleSetParam} handleInitializeParams={handleInitializeParams}/>}
            {type === 'sendPhoto' && <SendPhotoSimulator handleSetParam={handleSetParam} handleInitializeParams={handleInitializeParams}/>}
        </Box>
    )
}
