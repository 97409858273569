import React, {useState} from "react";
import {Backdrop, Box, CircularProgress, DialogContent, Snackbar, Stack, Tab, Typography} from "@mui/material";
import {HomeSetup} from "./EventSettings/HomeSetup";
import {ColorsSetup} from "./EventSettings/ColorsSetup";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {Alert} from "../shared/Alert";
import {TeamsSetup} from "./EventSettings/TeamsSetup";
import {useCollection} from "react-firebase-hooks/firestore";
import {firestore} from "../../firebase/clientApp";

export const EventSettings = ({event, closeSettings}) => {
    const [value, setValue] = React.useState('1');
    const [saving, setSaving] = useState(false)
    const [success, setSuccess] = useState(false)
    const [teams, loadingTeams, error] = useCollection(firestore.collection(`users/plesh/events/${event.id}/teams`))

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleSetSaving = (bool) => {
        setSaving(bool)
    }

    const handleSetSuccess = (bool) => {
        setSuccess(bool)
    }

    return (
        <DialogContent sx={{minHeight:'35rem'}}>
            <Stack direction={"column"}>
                <Snackbar open={success} autoHideDuration={2000} onClose={() => handleSetSuccess(false)}>
                    <Alert onClose={() => handleSetSuccess(false)} severity="success" sx={{ width: '100%' }}>
                        Impostazioni salvate con successo
                    </Alert>
                </Snackbar>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={saving || loadingTeams}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Typography variant={"h4"}>Impostazioni</Typography>
                {event && teams &&
                    <TabContext value={value}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <TabList onChange={handleChange}>
                                <Tab label="Schermata home" value="1"/>
                                <Tab label="Colori" value="2"/>
                                {event.data().squadre && <Tab label="Squadre" value="3"/>}
                            </TabList>
                        </Box>
                        <TabPanel value="1"><HomeSetup teams={teams} handleSetSuccess={handleSetSuccess}
                                                       handleSetSaving={handleSetSaving} closeSettings={closeSettings}
                                                       event={event}/></TabPanel>
                        <TabPanel value="2"><ColorsSetup handleSetSuccess={handleSetSuccess}
                                                         handleSetSaving={handleSetSaving} event={event}
                                                         closeSettings={closeSettings}/></TabPanel>
                        <TabPanel value="3"><TeamsSetup teams={teams} handleSetSuccess={handleSetSuccess}
                                                        handleSetSaving={handleSetSaving} event={event}
                                                        closeSettings={closeSettings}/></TabPanel>
                </TabContext>}
            </Stack>
        </DialogContent>
    )
}
