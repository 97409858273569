import {Box, Grid, IconButton, Paper, Stack, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {AddCircle, RemoveCircle} from "@mui/icons-material";
import {SingleColorSetup} from "../EventDetail/EventSettings/SingleColorSetup";
import {ChromePicker} from "react-color";
import {pleshDark} from "../../theme/pleshDark";
import {CustomColorPicker} from "../shared/CustomColorPicker";

const LinkForm = ({links, handleSetLinks}) => {
    const [_links, updateLinks] = useState([])

    useEffect(() => {updateLinks(links)},[links])

    function addNewLink() {
        let e = ''
        let r = _links
        let newR = r?.concat(e)
        handleSetLinks(newR)
    }

    function handleUpdateLink({i,value}) {
        let r = _links
        r[i] = value
        handleSetLinks(r)
    }

    function handleRemoveLink({i}) {
        let r = _links
        r?.splice(i,1)
        handleSetLinks(r)
    }

    /*function handleHideResult(event) {
        setHideResult(event.target.checked)
    }*/

    return (
        <Box sx={{pt: 3}}>
            <Typography variant={"h5"}>Add links below</Typography>
            <Stack direction={"column"}>
                {_links?.map((r,i) =>
                    <InputLink
                        i={i}
                        links={_links[i]}
                        handleUpdateLink={handleUpdateLink}
                        handleRemoveLink={handleRemoveLink}
                    />
                )}
            </Stack>
            <IconButton onClick={addNewLink}>
                <AddCircle/>
            </IconButton>
        </Box>
    )
}

export default LinkForm

function InputLink({i, handleUpdateLink, handleRemoveLink, links}) {
    const [link, setLink] = useState({
        label: "",
        url: "",
        color: '#E85D74'
    })

    useEffect(() => setLink(links), [links])

    function handleSetLink (event) {
        let value = {...link, label: event.target.value}

        setLink(value)
        handleUpdateLink({i, value})
    }

    function handleSetUrl (event) {
        let value = {...link, url: event.target.value}

        setLink(value)
        handleUpdateLink({i, value})
    }

    const updateColor = (hex) => {
        let value = {...link, color: hex}

        setLink(value)
        handleUpdateLink({i, value})
    }

    return (
        <Grid my={0.5} container alignItems={'center'}>
            <Grid item xs={1}>
                <IconButton onClick={() => handleRemoveLink({i})}>
                    <RemoveCircle />
                </IconButton>
            </Grid>
            <Grid item xs={11}>
                <Grid component={Paper} p={1} container borderRadius={'12px'}>
                    <Grid item xs={1}>
                        <CustomColorPicker color={link.color} handleUpdateColor={updateColor}/>
                    </Grid>
                    <Grid item xs={11}>
                        <TextField required value={link?.label} onChange={handleSetLink} label={'Testo del link ' + (i + 1)} variant={"standard"} fullWidth/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField required value={link?.url} onChange={handleSetUrl} label={'Inserisci l\'URL'} variant={"standard"} fullWidth/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
