import React from "react";
import {Box, IconButton, Stack, Typography} from "@mui/material";
import {SingleColorSetup} from "./SingleColorSetup";
import {Replay} from "@mui/icons-material";

export const CategoryColorSetup = ({theme, handleUpdateColor, resetTheme}) => {
    return(
        <Stack direction={{xs:'column', md:'row'}} alignItems={'start'} spacing={{xs:2, md:8}}>
            <Box>
                <Typography variant={"h6"}>Tema</Typography>
                <Stack direction={'row'} spacing={2}>
                    {theme.colors.map((c, i) => <SingleColorSetup category={'colors'} index={i} theme={theme} key={i} color={c} handleUpdateColor={handleUpdateColor}/>) }
                </Stack>
            </Box>
            <Stack alignItems={{xs:'start', md:'center'}}>
                <Typography variant={"h6"}>Background</Typography>
                <SingleColorSetup theme={theme} color={theme.background} handleUpdateColor={handleUpdateColor} category={'background'}/>
            </Stack>
            <Stack alignItems={{xs:'start', md:'center'}}>
                <Typography variant={"h6"}>Testo</Typography>
                <SingleColorSetup theme={theme} color={theme.text} handleUpdateColor={handleUpdateColor} category={'text'}/>
            </Stack>
            <Box sx={{textAlign:'center'}}>
                <Typography variant={"h6"}>Reset</Typography>
                <IconButton onClick={() => resetTheme(theme.id)}>
                    <Replay/>
                </IconButton>
            </Box>
        </Stack>
    )
}
