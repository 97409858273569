import React from "react";
import {
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField
} from "@mui/material";

export const SimulatorField = ({field, handleSetParam, font}) => {
  return(
        <Box>
            {
                field.type === "number" &&
                    <Box>
                        <TextField
                            variant={'standard'}
                            label={field.name}
                            defaultValue={field.default} type={'number'}
                                   onChange={(event) => handleSetParam(field.param, event.target.value)}/>
                    </Box>
            }
            {
                field.type === "radio" && <Box>
                    <FormControl>
                        <FormLabel id="simulator-radio-group-label">{field.name}</FormLabel>
                        <RadioGroup
                            aria-labelledby="simulator-radio-group-label"
                            name="simulator-radio-group"
                            defaultValue={field.default}
                            onChange={(event) => handleSetParam(field.param, event.target.value)}
                        >
                            {
                                field.options.map((o,i) =>
                                    <FormControlLabel key={i} value={o.value} control={<Radio />} label={o.label} />
                                )
                            }
                        </RadioGroup>
                    </FormControl>
                </Box>
            }
            {
                field.type === "select" && <Box>
                    <FormControl fullWidth variant={'standard'}>
                        <InputLabel id="simulator-select-label">{field.name}</InputLabel>
                        <Select
                            labelId="simulator-select-label"
                            id="simulator-select"
                            defaultValue={field.default}
                            label={field.name}
                            onChange={(event) => handleSetParam(field.param, event.target.value)}
                        >
                            {
                                field.options.map((o,i) =>
                                    <MenuItem key={i} value={o.value} sx={{fontFamily: font ? o.value : 'inherit'}}>{o.label}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Box>
            }
        </Box>
  )
}