export const sendPhotoOptions = [
    {
        param: "columns",
        name: "Colonne",
        type: "number",
        default: 12,
    },
    {
        param: "spacing",
        name: "Spaziatura",
        type: "number",
        default: 3
    },
    {
        param: "mode",
        name:"Modalità",
        type: "select",
        default: "normal",
        options: [
            {
                value: "normal",
                label: "Normale"
            },
            {
                value: "luma",
                label: "Luma"
            }
        ]
    }
]