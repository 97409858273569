import React, {useEffect, useState} from "react";
import {
    Accordion, AccordionDetails, AccordionSummary,
    Box,
    Button,
    capitalize,
    Card,
    Checkbox,
    Stack,
    TextareaAutosize,
    TextField,
    Typography
} from "@mui/material";
import {firestore} from "../../../firebase/clientApp";
import {FileUploader} from "react-drag-drop-files";
import axios from "axios";
import {useParams} from "react-router-dom";
import {ArrowDownward, ArrowUpward, ExpandMore, RotateLeft, Upload} from "@mui/icons-material";
import {API_URL} from "../../../config";
import {TeamsSetup} from "./TeamsSetup";
import CustomConfirmDialog from "../../shared/CustomConfirmDialog";
import DialogActions from "@mui/material/DialogActions";

const fileTypes = ["JPEG", "PNG", "JPG"];

export const HomeSetup = ({event, teams, closeSettings, handleSetSaving, handleSetSuccess}) => {
    const {id} = useParams()
    const [home, setHome] = useState({})
    const [contenutoAgenda, setContenutoAgenda] = useState('')
    const [titoloAgenda, setTitoloAgenda] = useState('')
    const [logo, setLogo] = useState(null);
    const [logoUrl, setLogoUrl] = useState('')
    const handleChangeLogo = (file) => {
        setLogo(file);
        setLogoUrl(URL.createObjectURL(file))
    };

    useEffect(() => {
        setHome({
            autenticazione: event.data().autenticazione,
            squadre: event.data().squadre,
            applausometro: event.data().applausometro,
            agenda: event.data().agenda,
            logo: event.data().logo
        })
        setContenutoAgenda(event.data().contenutoAgenda)
        setTitoloAgenda(event.data().titoloAgenda)
        setLogoUrl(event.data().logoUrl)
    }, [event])

    const handleSetHome = (e, field) => {
        setHome({...home, [field]: e.target.checked})
    }

    const changeAgenda = (e) => {
        setContenutoAgenda(e.target.value)
    }

    const changeAgendaTitle = (e) => {
        setTitoloAgenda(e.target.value)
    }

    const saveSettings = async (e) => {
        handleSetSaving(true)
        const formData = new FormData();
        formData.append('file', logo);
        if (logo !== null) {
            await axios({
                url: `${API_URL}/events/${event.id}/logo`,
                method: 'POST',
                data: formData,
            }).then()
        }
        await firestore.doc('users/plesh/events/' + event.id).update({
            autenticazione: home.autenticazione,
            applausometro: home.applausometro,
            agenda: home.agenda,
            logo: home.logo,
            contenutoAgenda,
            titoloAgenda,
            squadre: home.squadre || false
        }).then(handleSetSuccess(true))
        handleSetSaving(false)

    }

    const handleResetApplausi = async (e) => {
        if(home.squadre) {
            handleSetSaving(true)

            for (let i = 0; i < teams.size; i++) {
                await axios.post(`${API_URL}/${id}/${teams.docs[i].data()?.label}/reset`)
            }

        }

        await axios.post(`${API_URL}/${id}/applausi/reset`)
            .then(r => {
                //resetResults(r.data)
            })
            .catch(e => {
                //setError(true)
            })
            .finally(() => {
                handleSetSaving(false)
            })
    }

    return (
        <Box sx={{width: '100%'}}>
            <Typography variant={"h5"}>Home</Typography>
            {Object.keys(home).map(o =>
                <Box my={1} sx={{width: '100%', p: 0.5}} key={o} component={Card}>
                    <Stack direction={'row'} alignItems={'center'}>
                        <Box>
                            <Checkbox checked={home[o]} onChange={(e) => handleSetHome(e, o)}/>
                        </Box>
                        <Typography variant={"h6"}>{capitalize(o)}</Typography>
                        {
                            o === 'applausometro' &&
                            <Box marginLeft={'auto'} px={1}>
                                <CustomConfirmDialog icon={<RotateLeft/>}
                                                     confirmAction={handleResetApplausi}
                                                     label={"Resetta gli applausi"}
                                                     confirmText={<>Sei sicuro? {home.squadre ? <i><br/>Verranno eliminati anche gli applausi delle singole squadre.</i> : ""}</>}
                                                     size={'small'}
                                />
                            </Box>
                        }
                    </Stack>
                    {o === 'logo' &&
                        home.logo &&
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMore/>}>
                                Impostazioni Logo
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box px={2}>
                                    <Typography variant={"body2"} sx={{mb: 1}}>Logo:</Typography>
                                    <FileUploader
                                        multiple={false}
                                        handleChange={handleChangeLogo}
                                        name="file"
                                        types={fileTypes}
                                        children={

                                            <Box sx={{
                                                width: '100%',
                                                maxWidth: '20rem',
                                                p: 2,
                                                border: '2px dashed white',
                                                borderRadius: '1rem',
                                                textAlign: 'center'
                                            }}>
                                                {logoUrl !== '' ?
                                                    <Box>
                                                        <img src={logoUrl} style={{width: '100%'}}/>
                                                        <Typography variant={"body2"} sx={{mb: 1}}>Clicca qui per sostituire il
                                                            tuo logo</Typography>
                                                        <Upload sx={{fontSize: '3rem'}}/>
                                                    </Box>
                                                    :
                                                    <Box>
                                                        <Typography variant={"body2"} sx={{mb: 1}}>Clicca qui per caricare il
                                                            tuo logo</Typography>
                                                        <Upload sx={{fontSize: '3rem'}}/>
                                                    </Box>
                                                }
                                            </Box>
                                        }
                                    />
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    }
                    {o === 'agenda' && home.agenda &&
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMore/>}>
                                Impostazioni Agenda
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box px={2}>
                                    <Box sx={{mb: 2}}>
                                        <TextField label={'Titolo blocco'} onChange={changeAgendaTitle} value={titoloAgenda}
                                                   variant={'standard'} style={{width: '100%'}}/>
                                    </Box>
                                    <Box>
                                        <Typography sx={{mb: 1}} variant={"body1"}>Contenuto agenda:</Typography>
                                        <TextareaAutosize onChange={changeAgenda} className={'agendaArea'} minRows={7}
                                                          value={contenutoAgenda} style={{resize: 'none', width: '100%'}}/>
                                    </Box>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    }
                    {o === 'squadre' && home.squadre &&
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMore/>}>
                                Impostazioni Squadre
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box px={2}>
                                    <TeamsSetup teams={teams} closeSettings={closeSettings} handleSetSaving={handleSetSaving} handleSetSuccess={handleSetSuccess}/>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    }
                </Box>
            )}
            <DialogActions>
                <Button onClick={() => closeSettings()}>Chiudi</Button>
                <Button variant={"contained"} onClick={saveSettings}>Salva</Button>
            </DialogActions>
        </Box>
    )
}
