import React, {useMemo} from 'react'
import {Box} from "@mui/material";
import {useParams} from "react-router-dom";
import {useObject} from "react-firebase-hooks/database";
import {db} from "../../../firebase/clientApp";
import {ref} from 'firebase/database';
import {useQuery} from "../../../custom-hooks/useQuery";

export default function GraficoSingolo() {
    const {mode} = useParams()
    const {id} = useParams()

    const query = useQuery()
    const maxClap = query.get('maxClap')
    const type = query.get('type')

    const [snapshots, loading, error] = useObject(ref(db,'users/plesh/events/'+id));

    const normalizedNumClap = useMemo(() => {
        const defVal = `min(0% , 100%)`
        if(!snapshots || !snapshots?.val())
            return defVal

        const val = type ? snapshots.val()[type] : snapshots.val().applausi

        try {
            const maxClapInt = Number.parseInt(maxClap)
            return val ? (!!maxClapInt) ?
                `min(${(100 / maxClapInt) * val}% , 100%)`
                : `min(${(Math.log10(val) * 24) || 0}% , 100%)`
                : defVal
        } catch (e) {
            return val ? `min(${(Math.log10(val) * 24) || 0}% , 100%)` : defVal
        }

    }, [maxClap, snapshots])

    return (
        <Box>
            {(snapshots && !loading && !error && snapshots.val()) &&
                <p
                    className={"css-clapnum"}
                    // style={{fontSize:'5rem'}}
                >
                    {type ? snapshots.val()[type] : snapshots.val().applausi}
                </p>}
            <div style={{width: '100%', marginTop:!mode ? '1vh' : '3vh', position:'relative'}}>
                {(snapshots && !loading && !error && snapshots.val() && normalizedNumClap) &&
                    <div style={{ borderRadius:'1.5rem',height: !mode ? '8vh' : '16vh'}}>
                        <div className={"css-clapgraph"} style={{
                            width: normalizedNumClap,
                        }}>

                        </div>
                    </div>
                }
            </div>
        </Box>

    )
}
