import React, {useEffect} from "react";
import {Box, Stack} from "@mui/material";
import {pollOptions} from "../../../assets/data/documentation/pollOptions";
import {SimulatorField} from "./SimulatorField";
import {sharedOptions} from "../../../assets/data/documentation/sharedOptions";

const quizParams = [
    ...sharedOptions,
    ...pollOptions,
    {
        param: "correct",
        name:"Mostra risposta corretta",
        type: "radio",
        default: "true",
        options: [
            {
                label: "Si",
                value: "true"
            },
            {
                label: "No",
                value: "false"
            }
        ]
    }
]

export const QuizSimulator = ({handleSetParam, handleInitializeParams}) => {

    useEffect(() => {
        handleInitializeParams(quizParams)
    }, []);

    return (
        <Box>
            <Stack spacing={2}>
                {quizParams.map((p, i) =>
                    <SimulatorField field={p} handleSetParam={handleSetParam}/>
                )}
            </Stack>
        </Box>
    )
}