export const sharedOptions = [
    {
        param: "question",
        name: "Mostra solo testo della domanda",
        type: "radio",
        default: "false",
        options: [
            {
                label: "Si",
                value: "true"
            },
            {
                label: "No",
                value: "false"
            }
        ]
    },
]