import React from "react";
import './App.css';
import {CssBaseline, ThemeProvider} from "@mui/material";
import {pleshDark} from "./theme/pleshDark";
import {Route, Routes} from 'react-router-dom'
import {Login} from "./pages/Login";
import Events from "./pages/Events";
import EventDetail from "./pages/EventDetail";
import Control from "./pages/Control";
import Dati from "./pages/Dati";
import {ClapNum} from "./pages/ClapNum";
import ProtectedRoute from "./components/ProtectedRoute";
import Documentation from "./pages/Documentation";
import {RankResults} from "./pages/RankResults";
import {ReactionsVisual} from "./pages/ReactionsVisual";
import CountdownTimer from "./components/Control/CountdownTimer";

function App() {

    return (
        <ThemeProvider theme={pleshDark}>
            <CssBaseline/>
            <Routes>
                <Route path='/login' element={<Login/>}/>
                <Route path='/control/:id' exact element={<Control/>}/>
                <Route path='/control/:id/timer' exact element={<CountdownTimer/>}/>
                <Route path='/control/:id/:mode' element={<Control/>}/>
                <Route path='/control/:id/:mode/:domanda' element={<Control/>}/>
                <Route path='/control/:id/dati' exact element={<Dati/>}/>
                <Route path='/control/:id/applausi' exact element={<ClapNum/>}/>
                <Route path='/control/:id/reactions' exact element={<ReactionsVisual/>}/>
                <Route path='/documentation' element={<Documentation/>}/>
                <Route path='/documentation/:page' element={<Documentation/>}/>
                <Route path='/results/:id' element={<RankResults/>}/>
                <Route path='/team-results/:id' element={<RankResults team/>}/>
                <Route element={<ProtectedRoute/>}>
                    <Route path='/' element={<Events/>}/>
                    <Route path='/events' element={<Events/>}/>
                    <Route path='/events/:id' element={<EventDetail/>}/>
                </Route>
            </Routes>
        </ThemeProvider>
    );
}

export default App;
