import {Simulator} from "../../components/Documentation/Simulator";

export const documentation = [
    {
        id:'home',
        title: 'Welcome!',
        subtitle:'Qui troverai tutte le informazioni necessarie per utilizzare al meglio 2ndStage.',
        sections:[
            {
                id:'simulator',
                content: <Simulator/>
            }
        ]
    },
    {
        id:'poll',
        title: 'Sondaggio',
        subtitle:'Qui troverai tutte le informazioni necessarie per i sondaggi',
        sections:[]
    },
    {
        id:'wordcloud',
        title: 'Wordcloud',
        subtitle:'Qui troverai tutte le informazioni necessarie per la wordcloud',
        sections:[]
    },
    {
        id:'post',
        title: 'Post',
        subtitle:'Qui troverai tutte le informazioni necessarie per inviare dati',
        sections:[]
    },
    {
        id:'get',
        title: 'Get',
        subtitle:'Qui troverai tutte le informazioni necessarie per prendere dati',
        sections:[]
    }
]
