import React, {useState} from "react";
import {Box} from "@mui/material";
import {ChromePicker} from "react-color";

export const SingleColorSetup = ({color, handleUpdateColor, index, theme, category}) => {
    const [open, setOpen] = useState(false)

    const popover = {
        position: 'absolute',
        zIndex: '99999'
    }

    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    }

    const updateColor = (event) => {
        handleUpdateColor(theme.id, category, event.hex, index)
    }

    return (
        <Box>
            <Box onClick={()=>setOpen(!open)} sx={{position:'relative', width: '2.5rem', height: '2.5rem', background: color, borderRadius:'0.4rem', border: '2px solid #4C4C7E',}}/>
            {open && <div style={ popover }>
                <div style={ cover } onClick={ ()=>setOpen(!open) }/>
                <ChromePicker color={color} onChange={updateColor}/>
            </div>}
        </Box>
    )
}
