import Tooltip from "@mui/material/Tooltip";
import {Backdrop, Box, Button, CircularProgress, IconButton, Stack} from "@mui/material";
import {OpenInNew, PlayArrow, StopCircle, Timer} from "@mui/icons-material";
import React, {useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import {firestore} from "../../firebase/clientApp";
import {useParams} from "react-router-dom";
import CountdownTimerWrapper from "./CountdownTimer";

const CustomTimerSettings = ({eventTimer}) => {
    const initialTimerState = {
        label: eventTimer?.label || "",
        hours: eventTimer?.hours || 0,
        minutes: eventTimer?.minutes || 0,
        seconds: eventTimer?.seconds || 0,
        startedAt: eventTimer?.startedAt || null,
        duration: eventTimer?.duration || 0
    }

    const {id} = useParams()
    const [loading, setLoading] = useState(false)
    const [timer, setTimer] = useState(initialTimerState)

    const [open, setOpen] = useState(false)

    const handleCancel = () => {
        setTimer(initialTimerState)
        setOpen(!open)
    }

    const toggleDialog = () => {
        setOpen(!open)
    }

    const handleNameChange = (event) => {
        setTimer({...timer, label: event.target.value})
    }

    const handleDurationChange = (event) => {
        const {id, value} = event.target;
        // Aggiorna lo stato
        if (value >= 0) {
            try {
                setTimer({...timer, [id]: Number.parseInt(value)})
            } catch (e) {
                console.log("setTimer e:", e)
            }
        }
    }

    const handleSave = async (e) => {
        e.preventDefault()
        setLoading(true)

        const durationInSeconds = timer.hours * 3600 + timer.minutes * 60 + timer.seconds
        await firestore.doc('users/plesh/events/' + id).update({
            timer: {
                ...timer,
                duration: durationInSeconds,
                startedAt: null
            },
        })
            .then(() =>
                setTimer({
                    ...timer,
                    duration: durationInSeconds,
                }))
            .finally(() => setLoading(false))
    }

    const handleStartTimer = async (e) => {
        await handleSave(e)
        const startedTime = new Date()
            await firestore.doc('users/plesh/events/' + id).set({
                timer: {
                    startedAt: startedTime
                },
            }, {merge: true})
                .then(() => setTimer({...timer, startedAt: startedTime}))
    }

    const handleStopTimer = async (e) => {
        setLoading(true)
        await firestore.doc('users/plesh/events/' + id).set({
            timer: {
                startedAt: null
            },
        }, {merge: true})
            .then(() => setTimer({...timer, startedAt: null}))
            .finally(() => setLoading(false))
    }

    return (
        <>
            <Tooltip title="Imposta timer">
                <IconButton size={'large'} onClick={toggleDialog}>
                    <Timer fontSize={'inherit'}/>
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={handleCancel}>
                <DialogTitle component={Stack} direction={'row'} alignItems={'center'} flexWrap={'wrap'}>
                    Impostazione Timer
                    <CountdownTimerWrapper initialTime={timer.duration} isLive={!!timer.startedAt} startedAt={timer.startedAt}/>
                    <Box>
                        <IconButton sx={{marginLeft: 'auto'}}
                                    disabled={!timer.startedAt}
                                    onClick={handleStopTimer}
                        >
                            <StopCircle/>
                        </IconButton>
                        <Button variant={'outlined'} endIcon={<PlayArrow/>}
                                disabled={(timer.startedAt || (!timer.hours && !timer.minutes && !timer.seconds)
                                || !(timer.startedAt || (eventTimer?.hours === timer?.hours
                                        && eventTimer?.minutes === timer?.minutes
                                        && eventTimer?.seconds === timer?.seconds
                                        /*&& eventTimer?.label === timer?.label*/)))}
                                onClick={handleStartTimer}
                        >
                            MANDA LIVE
                        </Button>
                        <Tooltip title={"Timer Viewer"} children={
                            <IconButton size={'small'} href={`/control/${id}/timer`} target={'_blank'}>
                                <OpenInNew fontSize={'small'}/>
                            </IconButton>
                        }/>
                    </Box>
                </DialogTitle>
                <form onSubmit={handleSave}>
                    <DialogContent>
                        <Stack px={2} spacing={3}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="label"
                                label="Etichetta Timer"
                                type="text"
                                fullWidth
                                //required
                                variant="standard"
                                onChange={handleNameChange}
                                value={timer.label}
                            />
                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="hours"
                                    label="Ore"
                                    type="number"
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleDurationChange}
                                    value={timer.hours}
                                />
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="minutes"
                                    label="Minuti"
                                    type="number"
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleDurationChange}
                                    value={timer.minutes}
                                />
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="seconds"
                                    label="Secondi"
                                    type="number"
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleDurationChange}
                                    value={timer.seconds}
                                />
                            </Stack>
                            {/*<TextField
                            label={"Durata Timer"}
                            variant="outlined"
                            value={timer.duration}
                            onChange={handleDurationChange}
                            error={error}
                            fullWidth
                            helperText={helperText}
                            placeholder="hh:mm:ss"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">⏰</InputAdornment>,
                            }}
                            inputProps={{
                                pattern: '^([0-1]\\d|2[0-3]):([0-5]\\d):([0-5]\\d)$',
                                title: 'Inserisci l\'orario nel formato hh:mm:ss',
                            }}
                        />*/}
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancel}>
                            Chiudi
                        </Button>
                        <Button type={'submit'} variant={'contained'} sx={{borderRadius: '0.2rem'}}
                                disabled={timer.startedAt || (eventTimer?.hours === timer?.hours
                                    && eventTimer?.minutes === timer?.minutes
                                    && eventTimer?.seconds === timer?.seconds
                                    /*&& eventTimer?.label === timer?.label*/)
                        }>
                            Salva
                        </Button>
                    </DialogActions>
                </form>
                <Backdrop open={loading} children={<CircularProgress/>}/>
            </Dialog>
        </>
    );
}

export default CustomTimerSettings