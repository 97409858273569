import React from "react";
import {Box} from "@mui/material";
import Header from "../components/Header";

export const Main = ({children}) => {
  return(
      <Box>
          <Header/>
          {children}
      </Box>
  )
}
