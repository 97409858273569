import React, {useEffect} from "react";
import {Stack} from "@mui/material";
import {SimulatorField} from "./SimulatorField";
import {pollOptions} from "../../../assets/data/documentation/pollOptions";
import {sharedOptions} from "../../../assets/data/documentation/sharedOptions";

const options = [
    ...sharedOptions,
    ...pollOptions
]

export const PollSimulator = ({handleSetParam, handleInitializeParams}) => {

    useEffect(() => {
        handleInitializeParams(options)
    }, []);

    return (
        <Stack spacing={2}>
            {
                options.map((o, i) =>
                    <SimulatorField field={o} key={i} handleSetParam={handleSetParam}/>
                )
            }
        </Stack>
    )
}
