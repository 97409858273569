import React from "react";
import {useObject} from "react-firebase-hooks/database";
import {ref} from "firebase/database";
import {db} from "../../../firebase/clientApp";
import {useParams} from "react-router-dom";

export const ClickToVisual = ({elementPlaying}) => {
    const {id} = useParams()
    const [snapshots, loading, error] = useObject(ref(db, 'users/plesh/events/' + id + '/queue/' + elementPlaying.id + '/answers'));

    return (
        <div style={{fontSize:'20rem'}}>
            {(snapshots && !loading && !error && snapshots.val() && snapshots.val().clap) ? snapshots.val().clap : '0'}
        </div>
    )
}
