import React from 'react'
import {Grid} from "@mui/material";
import GraficoSingolo from "./Grafici/GraficoSingolo";

export default function ControlGrafici() {

    return(
        <Grid container sx={{width:'100%', height: '100%'}} style={{textTransform:'uppercase'}} alignContent={"center"} alignItems={"center"} justifyContent={"center"} spacing={1}>
            <Grid item xs={12}>
                <GraficoSingolo/>
            </Grid>
        </Grid>
    )
}
