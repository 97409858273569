import React, {useEffect} from "react";
import {
    Box,
    Card,
    CardContent,
    Container,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography
} from "@mui/material";
import {CopyAll} from "@mui/icons-material";
import {SimulatorContent} from "./Simulator/SimulatorContent";

export const Simulator = () => {
    const [type, setType] = React.useState('')
    const [value, setValue] = React.useState('')
    const [params, setParams] = React.useState({})

    const handleChange = (event) => {
        setType(event.target.value);
        setParams({})
    }

    useEffect(() => {
        let v = ''
        Object.keys(params).map((key,i) => {
            return v = v + (i === 0 ? '?' : '&') + key + '=' + params[key]
        })
        setValue(v)
    }, [params]);

    const handleInitializeParams = (options) => {
        options.forEach(o => {
            handleSetParam(o.param, o.default)
        })
    }

    const handleSetParam = (name, value) => {
        setParams(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(value).then(r => alert('Copiato!'))
    }

    return (
        <Box>
            <Container maxWidth={'md'} sx={{py: 2}}>
                <Card>
                    <CardContent>
                        <Box sx={{pb:2}}>
                            <Typography variant={'h5'} sx={{pb:2}} fontWeight={600}>
                                Simulatore
                            </Typography>
                            <Typography variant={'subtitle2'} sx={{opacity:'0.8'}}>
                                Scegli il tipo di domanda e imposta i parametri
                            </Typography>
                        </Box>
                        <Stack>
                            <FormControl variant="filled" fullWidth>
                                <InputLabel id="type-select-label">Tipo di domanda</InputLabel>
                                <Select
                                    id="type-select"
                                    value={type}
                                    onChange={handleChange}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={'poll'}>Sondaggio</MenuItem>
                                    <MenuItem value={'quiz'}>Quiz</MenuItem>
                                    <MenuItem value={'wordcloud'}>Wordcloud</MenuItem>
                                    <MenuItem value={'sendPhoto'}>Invio foto</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                        {type !== '' && <SimulatorContent type={type} handleSetParam={handleSetParam} handleInitializeParams={handleInitializeParams}/>}
                        {value!=='' &&
                            <Box sx={{pt:2}}>
                                <Typography variant={'caption'} sx={{opacity:'0.7'}}>Aggiungi il testo qui sotto alla fine del link della domanda</Typography>
                                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                    <Typography sx={{overflowWrap:'anywhere'}}>
                                        {value}
                                    </Typography>
                                    <Box>
                                        <IconButton onClick={handleCopy}>
                                            <CopyAll/>
                                        </IconButton>
                                    </Box>
                                </Stack>
                            </Box>
                        }
                    </CardContent>
                </Card>
            </Container>
        </Box>
    )
}
